import React, { useState, useEffect } from 'react';
import {
  Box,
  Center,
  Divider,
  AccordionItem,
  Flex,
  AccordionButton,
  AccordionPanel,
  Text,
  AccordionIcon,
  HStack,
  Button,
  IconButton,
  Image,
  useDisclosure,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useBreakpointValue,
} from '@chakra-ui/react';
import ReactGA from 'react-ga';

import { useTranslation } from 'react-i18next';

import { usePoolInfo } from '../../api/query';
import Cards from '../cards/index';

import Title from '../title';
import gift from '../../assets/gift.svg';
import { formatBalance, formatNumber } from '../../utils';
import StackModal from '../stakeModal';
import accountStore from '../../stores/account';

const Pool = (props: any) => {
  const btnSize = useBreakpointValue({ base: 'md', md: 'sm', xl: 'md' });
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { pool } = props;
  const { t } = useTranslation();
  const { id, icon, title, desc, token, pointName, name } = pool;
  const { data = {}, refetch } = usePoolInfo(id);
  const { stakedBal = 0, userPoints = 0 } = data;
  const { points } = accountStore.useState('points');

  const poolInfo = {
    ...pool,
    ...data,
  };

  const handleBtn = (e: any) => {
    e.preventDefault();
    ReactGA.modalview(`/stake/${name}/openModal`);
    onOpen();
  };

  useEffect(() => {
    // points[id] = userPoints;
    accountStore.setState({
      points: {
        ...points,
        [id]: userPoints,
      },
    });
  }, [userPoints, id]);

  useEffect(() => {
    setInterval(() => {
      refetch();
    }, 10000);
  }, []);
  // if (!data.poolInfo || data.poolInfo.rewardRate === '0') {
  //   return null;
  // }
  return (
    <Box>
      <AccordionItem border="none" _hover={{ bg: 'white' }}>
        {({ isExpanded }) => (
          <>
            <AccordionButton borderRadius="12px" textAlign="left" d="block">
              <Flex flexWrap="wrap" justify="space-between">
                <HStack spacing={{ base: '8px', '2xl': '16px' }} mr={{ base: 0, xl: 8 }}>
                  <Box w={{ base: '32px', md: '42px', xl: '48px' }}>
                    <Image
                      w="100%"
                      src={icon}
                      borderRadius={10}
                      boxShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
                    />
                  </Box>
                  <Box minW="232px">
                    <Title title={title} token={token.name} pointName={pointName} desc={desc} />
                  </Box>
                </HStack>

                <StatGroup
                  d="flex"
                  alignItems="center"
                  textAlign="left"
                  whiteSpace="nowrap"
                  mt={{ base: 1, md: 2, lg: 0 }}
                  ml={{ base: 10, md: 3 }}
                >
                  <Stat
                    mr={{ base: 0, lg: 4 }}
                    w={{ base: '204px', md: '120px', lg: '132px', xl: '204px' }}
                    visibility={token.name ? 'visible' : 'hidden'}
                  >
                    <StatLabel fontSize={{ base: 11, lg: 12 }}>
                      {t('uesr.staked')} {token.name}
                    </StatLabel>
                    <StatNumber
                      fontSize={{ base: 12, lg: 18 }}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      fontFamily="Eurostile"
                    >
                      <Image
                        w={{ base: '16px', md: '20px', lg: '24px' }}
                        h={{ base: '16px', md: '20px', lg: '24px' }}
                        src={token.icon}
                        position="absolute"
                        left={{ base: '-26px', lg: '-32px', xl: '-36px' }}
                        borderRadius="full"
                      />
                      <Box as="span">
                        {formatNumber(formatBalance(stakedBal)).split('.')[0]}
                        {formatNumber(formatBalance(stakedBal)).split('.')[1] ? '.' : ''}
                      </Box>
                      <Box as="span" fontSize={{ base: 10, lg: 14 }}>
                        {formatNumber(formatBalance(stakedBal)).split('.')[1]}
                      </Box>
                    </StatNumber>
                  </Stat>
                  <Stat w={{ base: '204px', md: '120px', lg: '132px', xl: '204px' }}>
                    <StatLabel fontSize={{ base: 11, lg: 12 }}> {t('dkp')}</StatLabel>
                    <StatNumber fontSize={{ base: 12, lg: 18 }} fontFamily="Eurostile">
                      <Box as="span">
                        {formatNumber(userPoints).split('.')[0]}
                        {formatNumber(userPoints).split('.')[1] ? '.' : ''}
                      </Box>
                      <Box as="span" fontSize={{ base: 10, lg: 14 }}>
                        {formatNumber(userPoints).split('.')[1]}
                      </Box>
                    </StatNumber>
                  </Stat>
                </StatGroup>

                <Box width={{ base: '100%', md: 'auto' }} mt={{ base: 4, md: 0 }}>
                  <Flex align="center" h="100%">
                    <Button
                      size={btnSize}
                      fontFamily="Eurostile"
                      fontSize="18px"
                      fontWeight="bold"
                      mr="12px"
                      px={8}
                      flexGrow={{ base: 1, md: 'inherit' }}
                      onClick={(e) => handleBtn(e)}
                      display={token.name ? 'block' : 'none'}
                    >
                      &nbsp;&nbsp;
                      {t('stake').toUpperCase()}&nbsp;&nbsp;
                    </Button>
                    <Button
                      size={btnSize}
                      fontFamily="Eurostile"
                      fontSize="11px"
                      fontWeight="bold"
                      mr="12px"
                      px={0}
                      flexGrow={{ base: 1, md: 'inherit' }}
                      letterSpacing="-.1px"
                      variant="ghost"
                      color="#999"
                      display={token.name ? 'none' : 'block'}
                    >
                      {t('finish.get').toUpperCase()}
                    </Button>
                    <IconButton
                      size={btnSize}
                      variant="outline"
                      aria-label="opt"
                      borderRadius="lg"
                      colorScheme="iconBtn"
                      icon={isExpanded ? <AccordionIcon /> : <Image w="20px" src={gift} />}
                    />
                  </Flex>
                </Box>
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4} borderRadius="12px">
              <Cards poolInfo={poolInfo} isExpanded cb={refetch} />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      {poolInfo && (
        <StackModal
          poolInfo={poolInfo}
          isOpen={isOpen}
          onClose={onClose}
          type="stake"
          cb={refetch}
        />
      )}
      <Center height="1px">
        <Divider width="90%" px={8} h="1px" />
      </Center>
    </Box>
  );
};

export default Pool;
