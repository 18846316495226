import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import ReactGA from 'react-ga';
import Panel from '../../components/panel';

const Page = () => {
  useEffect(() => {
    ReactGA.pageview('/disclaimer');
  }, []);

  return (
    <Panel p={{ base: 4, md: 8 }} border="0" overflow="hidden" minH="200px">
      <Box fontFamily="Eurostile">
        <Text as="h2" textAlign="center" fontWeight="bold" fontSize="20px" my="5">
          Terms of Use
        </Text>
        <Text my="3">
          This page applies only to projects launched on or before November 15th, 2021 at 11:59pm
          ET. For the current version of these terms, please click here.
        </Text>
        <Text my="3">
          Please read these Terms of Use (the “DKP” or “Terms of Use”) carefully before using the
          services offered by DKP. This Agreement sets forth the legally binding terms and
          conditions for your use of the website at www.dkp.land, all other sites owned and operated
          by DKP that redirect to www.dkp.land, and all subdomains (collectively, the “Site”), and
          the service owned and operated by the Company (together with the Site, the “Service”). By
          using the Service in any manner, including, but not limited to, visiting or browsing the
          Site or contributing content, information, or other materials or services to the Site, you
          agree to be bound by this Agreement.
        </Text>
        <Text as="h3" fontWeight="bold" fontSize="18px" my="4">
          Summary of Service
        </Text>
        <Text my="3">
          DKP is a platform where certain users (“Project Creators”) run campaigns to fund creative
          projects by offering rewards to raise money from other users (“Backers”). Through the
          Site, email, websites, and other media, the Service makes accessible various content,
          including, but not limited to, videos, photographs, images, artwork, graphics, audio
          clips, comments, data, text, software, scripts, projects, other material and information,
          and associated trademarks and copyrightable works (collectively, “Content”). Project
          Creators, Backers, and other visitors to and users of the Service (collectively, “Users”)
          may have the ability to contribute, add, create, upload, submit, distribute, facilitate
          the distribution of, collect, post, or otherwise make accessible (“Submit”) Content. “User
          Submissions” means any Content Submitted by Users.
        </Text>
        <Text as="h3" fontWeight="bold" fontSize="18px" my="4">
          Rules and Conduct
        </Text>
        <Text my="3">
          As a condition of use, you promise not to use the Service for any purpose that is
          prohibited by the Terms of Use or law. The Service is provided only for your own personal,
          non-commercial use (except as allowed by the terms set forth in the section of these Terms
          of Use titled, “Projects: Fundraising and Commerce”). You are responsible for all of your
          activity in connection with the Service. You shall not, and shall not permit any third
          party using your account to, take any action, or Submit Content, that:
        </Text>
        <Text my="3">
          infringes any patent, trademark, trade secret, copyright, right of publicity, or other
          right of any other person or entity, or violates any law or contract;
        </Text>
        <Text my="3">you know is false, misleading, or inaccurate;</Text>
        <Text my="3">
          is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent,
          tortious, obscene, offensive, profane, or invasive of another’s privacy;
        </Text>
        <Text my="3">
          constitutes unsolicited or unauthorized advertising or promotional material or any junk
          mail, spam, or chain letters;
        </Text>
        <Text my="3">
          contains software viruses or any other computer codes, files, or programs that are
          designed or intended to disrupt, damage, limit, or interfere with the proper function of
          any software, hardware, or telecommunications equipment or to damage or obtain
          unauthorized access to any system, data, password, or other information of the platform or
          any third party;
        </Text>
        <Text my="3">
          is made in breach of any legal duty owed to a third party, such as a contractual duty or a
          duty of confidence; or impersonates any person or entity, including any employee or
          representative of the platform.
        </Text>
        <Text my="3">
          Additionally, you shall not: (i) take any action that imposes or may impose (as determined
          by the platform in its sole discretion) an unreasonable or disproportionately large load
          on the platform’s or its third-party providers’ infrastructure; (ii) interfere or attempt
          to interfere with the proper working of the Service or any activities conducted on the
          Service; (iii) bypass any measures the platform may use to prevent or restrict access to
          the Service (or other accounts, computer systems, or networks connected to the Service);
          (iv) run Maillist, Listserv, or any form of auto-responder or “spam” on the Service; or
          (v) use manual or automated software, devices, or other processes to “crawl” or “spider”
          any page of the Site.
        </Text>
        <Text my="3">
          You shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse
          engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms
          of any part of the Service, except to the extent applicable laws specifically prohibit
          such restriction; (ii) modify, translate, or otherwise create derivative works of any part
          of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the
          rights that you receive hereunder. You shall abide by all applicable local, state,
          national, and international laws and regulations.
        </Text>
        <Text my="3">
          Project Creators agree to not abuse other users’ personal information. Abuse is defined as
          using personal information for any purpose other than those explicitly specified in the
          Project Creator’s Project, or is not related to fulfilling delivery of a product or
          service explicitly specified in the Project Creator’s Project.
        </Text>
      </Box>
    </Panel>
  );
};

export default Page;
