export type Config = 'disableCards' | 'poolConfig' | 'redeemConfig';

export const getDKPConfig = async (config: Config) => {
  try {
    // const url = `https://raw.githubusercontent.com/DAOSquare/dkp-data/main/config/${config}.json`;
    const url = `/data/config/${config}.json`;

    const res = await fetch(url);
    const data = await res.json();

    return data;
  } catch (error) {
    console.log('get config file error: ', error);
    return {};
  }
};

export const getDKPCardMetadata = async (cardId: string) => {
  try {
    const url = `/data/metadata/${cardId}.json`;

    const res = await fetch(url);
    const data = await res.json();

    if (data.image) {
      const imageUrl = data.image;
      const imageName = imageUrl.split('/').pop();
      data.image = `/data/images/${imageName}`;
    } else if (data.type === 'video') {
      const videoUrl = data.url;
      const videoName = videoUrl.split('/').pop();
      data.url = `/data/videos/${videoName}`;
    }

    return data;
  } catch (error) {
    console.log('get metadata file error: ', error);
    return {};
  }
};
