import dkpool from './DKPool.json';
import nfts from './NFTs.json';
import erc20 from './ERC20.json';
import checkABI from './ICheck.json';

import {
  getPoolAddr,
  getNFTAddr,
  getContract,
  callContract,
  getCurrentAccountAddr,
  writeContract,
  getTokenContract,
  convertAmount,
  isAddress,
} from '../../utils/web3';

import { APPROVE_LIMIT } from '../../config/constants';

// dkpool contract api
export const dkpoolApi = () => {
  const contractAddress = getPoolAddr();
  const contract = getContract(contractAddress, dkpool.abi);
  // query card release time
  const cardReleaseTime = async (poolId: number, cardId: number) => {
    const res = await callContract(contract, 'cardReleaseTime', [poolId, cardId]);
    return res;
  };

  // query point about card
  const cardPoints = async (poolId: number, cardId: number) => {
    const res = await callContract(contract, 'cardPoints', [poolId, cardId]);
    return res;
  };

  // query farm earned point
  const earned = async (poolId: number, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'earned', [address, poolId]);
    return res;
  };
  // query dynamicDKP
  const getDynamicDKP = async (poolId: number, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'getDynamicDKP', [poolId, address]);
    return res;
  };

  // query user points for pool
  const farmerPoints = async (poolId: number, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'getDKP', [poolId, address]);
    return res;
  };

  // query support token
  const poolToken = async (poolId: number) => {
    const res = await callContract(contract, 'poolSupportToken', [poolId]);
    return res;
  };

  // query support token
  const getPoolInfo = async (poolId: number) => {
    const res = await callContract(contract, 'pools', [poolId]);
    return res;
  };

  // query support token
  const getPool = async (poolId: number) => {
    const res = await callContract(contract, 'getPool', [poolId]);
    return res;
  };

  // query support token
  const getCard = async (poolId: number, cardId: number) => {
    const res = await callContract(contract, 'getCard', [poolId, cardId]);
    return res;
  };

  // query card list
  const getCards = async (poolId: number) => {
    const res = await callContract(contract, 'getCardList', [poolId]);
    return res;
  };

  // query card list
  const getRedeemCount = async (poolId: number, cardId: number, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'cardRedeemCount', [poolId, cardId, address]);
    return res;
  };

  // query total donation
  const getTotalDonate = async (poolId: number, addr: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'pcTotalDonate', [poolId, address]);
    return res;
  };
  // query support token
  // const getCardIndex = async (poolId: number) => {
  //   const res = await callContract(contract, 'lastCardIndex', [poolId]);
  //   return res;
  // };

  // query pool stake token
  const getUserPoolBalance = async (poolId: number, addr: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'balanceOf', [address, poolId]);
    return res;
  };

  // query pool stake token
  const getPoolBalance = async (poolId: number) => {
    const res = await callContract(contract, 'balanceOfPool', [poolId]);
    return res;
  };

  // query card requirements
  const getCardRequirements = async (cardId: number) => {
    const res = await callContract(contract, 'getCardRequiredNFTS', [cardId]);
    return res;
  };

  // query locked nft for cards
  const getLockedNFTs = async (cardId: number, contracts: string, id: number) => {
    const res = await callContract(contract, 'balanceOfPool', [cardId, contracts, id]);
    return res;
  };

  // ====== write

  // staking token to pool
  const addRequirment = async (
    poolId: number,
    cardId: number,
    contracts = [],
    ids = [],
    amounts = [],
    cbs: any,
  ) => {
    const res = await writeContract(
      contract,
      'addRequireNFTs',
      [poolId, cardId, contracts, ids, amounts],
      cbs,
    );
    return res;
  };

  // staking token to pool
  const stake = async (poolId: number, amount: number, cbs: any) => {
    const res = await writeContract(contract, 'stake', [poolId, amount], cbs);
    return res;
  };
  // staking token to pool
  const withdraw = async (poolId: number, amount: number, cbs: any) => {
    const res = await writeContract(contract, 'withdraw', [poolId, amount], cbs);
    return res;
  };

  // redeem cards with pool points
  const redeem = async (poolId: number, cardId: number, cbs: any) => {
    const res = await writeContract(contract, 'redeem', [poolId, cardId], cbs);
    return res;
  };

  // staking token to ovo pool
  const donate = async (poolId: number, amount: number, cbs: any) => {
    // const address = await getCurrentAccountAddr();

    const res = await writeContract(contract, 'donate', [poolId, convertAmount(amount)], cbs);
    return res;
  };

  return {
    ...contract,
    cardReleaseTime,
    cardPoints,
    earned,
    getDynamicDKP,
    farmerPoints,
    stake,
    withdraw,
    redeem,
    donate,
    poolToken,
    getPoolInfo,
    getPool,
    getCard,
    getCards,
    getTotalDonate,
    getUserPoolBalance,
    getPoolBalance,
    addRequirment,
    getLockedNFTs,
    getCardRequirements,
    getRedeemCount,
  };
};

export const checkAPI = (checkAddr: string) => {
  if (!checkAddr || checkAddr === '0x0000000000000000000000000000000000000000') {
    return null;
  }
  const contract = getContract(checkAddr, checkABI.abi);

  const dkpAddr = getPoolAddr();
  const check = async (poolId: number, cardId: number, addr: string) => {
    try {
      const address = addr || (await getCurrentAccountAddr());
      const res = await callContract(contract, 'check', [dkpAddr, poolId, cardId, address]);
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    ...contract,
    check,
  };
};

// ERC1155 API
export const nftsApi = () => {
  const contractAddress = getNFTAddr();
  const contract = getContract(contractAddress, nfts.abi);

  // query 1155 token
  const getCardBal = async (tokenId: number, addr: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'balanceOf', [address, tokenId]);
    return res;
  };
  // query 1155 token
  const getCardLimt = async (tokenId: number) => {
    const res = await callContract(contract, 'maxSupply', [tokenId]);
    return res;
  };
  // query 1155 token total supply
  const getCardTotalSupply = async (tokenId: number) => {
    const res = await callContract(contract, 'totalSupply', [tokenId]);
    return res;
  };

  // query approve from contract
  const isApprovedForAll = async (contractAddr: string, addr?: string) => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'isApprovedForAll', [address, contractAddr]);
    return res;
  };

  // ===== write ======

  // approve from contract
  const setApprovalForAll = async (contractAddr: string, cbs: any) => {
    const res = await writeContract(contract, 'setApprovalForAll', [contractAddr, true], cbs);
    return res;
  };

  return {
    ...contract,
    getCardBal,
    getCardLimt,
    getCardTotalSupply,
    isApprovedForAll,
    setApprovalForAll,
  };
};

// ERC20 API
export const tokenApi = (name = '') => {
  let tokenAddr;
  if (isAddress(name)) {
    tokenAddr = name;
  } else {
    const symbol = name.toUpperCase();
    tokenAddr = getTokenContract(symbol);
  }
  const contract = getContract(tokenAddr, erc20.abi);

  // get token name
  const getName = async () => {
    const res = await callContract(contract, 'name', []);
    return res;
  };

  // query balance of erc20 token
  const getBalance = async (addr = '') => {
    const address = addr || (await getCurrentAccountAddr());
    const res = await callContract(contract, 'balanceOf', [address]);
    return res;
  };

  // get approve amount with address
  const getAllowance = async (contractAddr: string) => {
    const address = await getCurrentAccountAddr();
    const res = await callContract(contract, 'allowance', [address, contractAddr]);
    return res;
  };

  // get total supply
  const totalSupply = async () => {
    const res = await callContract(contract, 'totalSupply', []);
    return res;
  };

  // approve token to address
  const approve = async (contractAddr: string, cbs: any, amount = APPROVE_LIMIT) => {
    const res = await writeContract(contract, 'approve', [contractAddr, amount], cbs);
    return res;
  };

  return { ...contract, getName, getBalance, getAllowance, approve, totalSupply };
};

export default {};
