import {
  useQuery,
  // useMutation,
} from 'react-query';
import { globalStore } from 'rekv';
import { tokenApi, dkpoolApi, nftsApi } from '../contracts';
import { getOVOAddr, getNFTAddr } from '../../utils/web3';
import { formatBalance, postQuery, postQueryRice } from '../../utils';
import { getDKPCardMetadata, getDKPConfig } from './dkpData';

export * from './queryClient';

const OVO_QUERY = 'getOVOInfo';
const POOL_QUERY = 'getPoolInfo';
// const MY_OVO_QUERY = 'getMyOVOInfo';
const MY_BAL_QUERY = 'getMyTokenBal';
const MY_POINTS_QUERY = 'getMyPoints';
const CARDS_QUERY_BY_POOLID = 'getCardsByPoolId';
const CARDS_QUERY_BY_INFO = 'getCardsByInfo';
const CARDS_QUERY_BY_IDS = 'getCardsByIds';
// const CARDS_QUERY_BY_REQS = 'getCardsByRequirements';
const CONFIG_QUERY = 'getConfig';
const QUERY_USER_NFT = 'getUserNFT';
const QUERY_USER_MAINNET_RICE = 'getUserMainnetRice';

export const getConnectedState = () => {
  const { appState = {} } = globalStore.useState('appState');
  const { connected = false } = appState;
  return connected;
};

export const getAddrState = () => {
  const { appState = {} } = globalStore.useState('appState');
  const { address = false } = appState;
  return address;
};

// query ovo info
export const useOVOInfo = (idx: number) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };P:10
  const getOVOInfo = async () => {
    // todo add cards
    const token = tokenApi('WXDAI');
    const ovoAddr = getOVOAddr();
    // get ovo Wxdai bal
    const bal = await token.getBalance(ovoAddr);
    const ovoBal = formatBalance(bal);
    const pool = dkpoolApi();
    const userPoints = await pool.earned(idx);
    const point = formatBalance(userPoints, 18);
    const poolInfo = await pool.getPoolInfo(idx);
    return { ovoBal, userPoints: point, poolInfo };
  };

  return useQuery(OVO_QUERY, getOVOInfo);
};

// query ovo info
export const usePoolInfo = (idx: number) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };
  const getPoolInfo = async () => {
    // todo add cards
    // get ovo Wxdai bal
    const pool = dkpoolApi();
    const stakedBal = await pool.getUserPoolBalance(idx);
    // const userPoints = await pool.farmerPoints(idx);
    const userPoints = await pool.earned(idx);
    // const userPoints = await pool.getDynamicDKP(idx);

    const poolInfo = await pool.getPoolInfo(idx);
    return {
      stakedBal,
      // userPoints: formatBalance(userPoints, 18),
      userPoints: formatBalance(userPoints, 18),
      poolInfo,
    };
  };
  return useQuery(`${POOL_QUERY}${idx}`, getPoolInfo);
};

// query balance for current address
export const useMyBalInfo = (name: string, poolId = 0) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };
  // const address = useAddrState()
  const getBalInfo = async () => {
    const token = tokenApi(name);
    const bal = await token.getBalance();
    let staked = 0;
    if (poolId > 0) {
      const pool = dkpoolApi();
      staked = await pool.getUserPoolBalance(poolId);
    }
    return { bal, staked };
  };

  return useQuery(`${MY_BAL_QUERY}${name.toLocaleUpperCase()}`, getBalInfo);
};

// query points for current address
export const useMyPoints = (idx: number) => {
  const getPoints = async () => {
    const pool = dkpoolApi();
    const res = await pool.farmerPoints(idx);
    return { myPoints: res };
  };

  return useQuery(`${MY_POINTS_QUERY}${idx}`, getPoints);
};

// query points for current address
export const queryCardsWithPoolId = (poolId: number, isWins = false) => {
  // const connect = getConnectedState();
  // if (!connect) return { data: {} };

  let pool: any = null;
  let nfts: any = null;

  const getCards = async () => {
    pool = dkpoolApi();
    nfts = nftsApi();
    const { cardList: ids } = await pool.getPool(poolId);
    let userPoints = 0;
    userPoints = await pool.earned(poolId);
    let reqs: any = [];
    // query cardinfo for pool
    const getCardInfo = async (cardId: string) => {
      try {
        const cardData = await pool.getCard(poolId, cardId);
        const metadata = await getDKPCardMetadata(cardId);
        const maxSupply = await nfts.getCardLimt(cardId);
        const totalSupply = await nfts.getCardTotalSupply(cardId);
        const redeemCount = await pool.getRedeemCount(poolId, cardId);
        let cardRequirements = {};
        const NFTs: any = {};

        if (isWins) {
          cardRequirements = await pool.getCardRequirements(cardId);
          if (Object.keys(cardRequirements).length > 0) {
            const ownedNFTAddr = getNFTAddr();
            let cardsInfo = [];
            const requests: any = [];
            const { requireNFTAmounts, requireNFTContracts, requireNFTIds } = cardRequirements;
            requireNFTContracts.map((addr: string, idx: number) => {
              const tokenId = requireNFTIds[idx];
              NFTs[tokenId] = {
                addr,
                contract: requireNFTContracts[idx],
                id: tokenId,
                amount: requireNFTAmounts[idx],
              };
              if (addr === ownedNFTAddr) {
                requests.push(getDKPCardMetadata(tokenId));
              }
              return null;
            });
            cardsInfo = await Promise.all(requests);
            cardsInfo.map((info: any) => {
              const { cardId: id } = info;
              const require = NFTs[id];
              NFTs[id] = { ...info, ...require };
              return null;
            });
          }
        }
        // console.log(maxSupply, totalSupply);

        return {
          ...cardData,
          ...metadata,
          maxSupply,
          totalSupply,
          poolId,
          cardId,
          userPoints,
          requirements: NFTs,
          redeemCount,
        };
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    reqs = ids.map((cardId: string) => {
      return getCardInfo(cardId);
    });

    // for (let i = 1; i <= +num; i += 1) {
    //   reqs.push();
    // }
    const cards = await Promise.all(reqs);
    return { cards };
  };

  return useQuery(`${CARDS_QUERY_BY_POOLID}${poolId}`, getCards);
};

export const queryCardByConfig = (cards: any) => {
  const pool = dkpoolApi();
  const nfts = nftsApi();
  const getCardInfo = async (poolId: number, cardId: number) => {
    const cardConfig = await pool.getCard(poolId, cardId);
    // console.log(cardId, '==', cardConfig);
    const metadata = await getDKPCardMetadata(cardId.toString());
    const maxSupply = await nfts.getCardLimt(cardId);
    const totalSupply = await nfts.getCardTotalSupply(cardId);
    const userPoints = await pool.earned(poolId);
    const redeemCount = await pool.getRedeemCount(poolId, cardId);

    return {
      ...cardConfig,
      ...metadata,
      maxSupply,
      totalSupply,
      poolId,
      cardId,
      userPoints,
      redeemCount,
    };
  };

  const getCards = async () => {
    const reqs = cards.map((card: any) => {
      const { poolId, cardId } = card;
      return getCardInfo(poolId, cardId);
    });
    const res = await Promise.all(reqs);
    return { cards: res };
  };

  return useQuery(`${CARDS_QUERY_BY_INFO}`, getCards);
};

// export const queryCardByIds = (cards: any) => {
//   const nfts = nftsApi();
//   const getCardInfo = async (card: any) => {
//     const { cardId } = card;
//     const metadata = await getMetadata(cardId.toString());
//     const maxSupply = await nfts.getCardLimt(cardId);
//     const totalSupply = await nfts.getCardTotalSupply(cardId);

//     return {
//       ...metadata,
//       maxSupply,
//       totalSupply,
//       ...card,
//     };
//   };

//   const getCards = async () => {
//     const reqs = cards.map((card: any) => {
//       return getCardInfo(card);
//     });
//     const res = await Promise.all(reqs);
//     return { cards: res };
//   };

//   return useQuery(`${CARDS_QUERY_BY_IDS}`, getCards);
// };

export const useConfig = () => {
  const getConfigData = async () => {
    const poolConfig = await getDKPConfig('poolConfig');
    const redeemConfig = await getDKPConfig('redeemConfig');
    const disableCards = await getDKPConfig('disableCards');
    return { poolConfig, redeemConfig, disableCards };
  };
  return useQuery(`${CONFIG_QUERY}`, getConfigData);
};

export const useUserNFT = (addr: string) => {
  const nfts = nftsApi();

  const getCardInfo = async (card: any) => {
    const { cardId } = card;
    const metadata = await getDKPCardMetadata(cardId.toString());
    const maxSupply = await nfts.getCardLimt(cardId);
    const totalSupply = await nfts.getCardTotalSupply(cardId);

    return {
      ...metadata,
      maxSupply,
      totalSupply,
      ...card,
    };
  };
  const getUserNFT = async () => {
    if (!addr) {
      return {};
    }
    const query = `{
      cardOwners(where:{owner:"${addr.toLowerCase()}"}){
        amount
        cardId
      }
    }`;
    const { cardOwners = [] } = await postQuery(query.toString(), {});

    return cardOwners;
  };

  const getOwnedCards = async () => {
    const cards = await getUserNFT();
    const reqs = cards.map((card: any) => {
      return getCardInfo(card);
    });
    const res = await Promise.all(reqs);
    return { cards: res };
  };

  return useQuery(`${QUERY_USER_NFT}`, getOwnedCards);
};

export const useUserRiceMainnet = (addr: string) => {
  const getUserMainnetRice = async () => {
    if (!addr) {
      return [];
    }
    const query = `{
  accounts(where:{id: "${addr.toLowerCase()}"}) {
    balances {
      value
    }
  }
}`;
    const { accounts = [] } = await postQueryRice(query.toString(), {});
    return accounts;
  };

  return useQuery(`${QUERY_USER_MAINNET_RICE}`, getUserMainnetRice);
};
