import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Text,
  Image,
  Center,
  Badge,
  Divider,
  Button,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import ApproveBtn from '../ApproveBtn';
import { formatBalance } from '../../utils';
import { getPoolAddr } from '../../utils/web3';
import checkIcon from '../../assets/check.svg';
import checkIconGrey from '../../assets/check-grey.svg';
import { checkAPI } from '../../api/contracts';

const MintModal = (props: any) => {
  const { t } = useTranslation();

  const [agreed, setAgreed] = useState(false);
  const [mintable, setMintable] = useState(true);
  const history = useHistory();

  const {
    isOpen,
    onClose,
    name,
    DKP = 0,
    maxSupply,
    totalSupply,
    image,
    poolId,
    expandPools = [],
    expandSpendingDKP = [],
    currentUserPoints = {},
    mintFee,
    feeToken,
    feeTokenName,
    feeTokenBal,
    redeem,
    loading,
    checkContract,
    cardId,
  } = props;

  useEffect(() => {
    if (!checkContract && checkContract !== '0x0000000000000000000000000000000000000000') {
      setMintable(false);
    }
  }, [checkContract]);

  const checkMintable = async (checkFun: any) => {
    const res = await checkFun.check(poolId, cardId);
    console.log(res, '====', poolId, cardId);
    if (res === true) {
      setMintable(res);
    } else {
      setMintable(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    const checkFun = checkAPI(checkContract);
    if (!checkFun) {
      setMintable(true);
      console.log(props);
    } else {
      checkMintable(checkFun);
    }
  }, [checkContract, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px={0} fontFamily="Eurostile">
          <Center
            zIndex={-1}
            pos="relative"
            w="100%"
            h={{ base: '320px', md: '345px' }}
            objectFit="cover"
            bg="linear-gradient(180deg, #FFFFFA 0%, #FFFAF0 100%)"
          >
            <Image maxW="96%" maxH="90%" src={image} />
            <Badge
              pos="absolute"
              right={3}
              top={7}
              borderRadius="20px"
              fontSize={10}
              bgColor="primary"
              border="1px solid white"
              textColor="white"
            >{`${maxSupply - totalSupply}/${maxSupply}`}</Badge>
          </Center>

          <Text fontSize={16} fontWeight="bold" mt={1} px={4}>
            {name}
          </Text>

          <Box px={8} mt={4} mb={2}>
            <Text color="primary" fontWeight="bold">
              {t('spend')}{' '}
              <NumberFormat
                value={formatBalance(DKP)}
                displayType={'text'}
                thousandSeparator={true}
              />
              {` DKP${poolId + 1}`}
            </Text>
            <Text fontSize={12} textColor="textTips" fontFamily="Arial">
              {t('balance')}: {currentUserPoints[poolId]}
            </Text>

            {expandPools.length > 0 &&
              expandPools.map((d, i) => (
                <Box key={i}>
                  <Text color="primary" fontWeight="bold">
                    {t('spend')}{' '}
                    <NumberFormat
                      value={formatBalance(expandSpendingDKP[i])}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    {` DKP${Number(d) + 1}`}
                  </Text>
                  <Text fontSize={12} textColor="textTips" fontFamily="Arial">
                    {t('balance')}: {currentUserPoints[d]}
                  </Text>
                </Box>
              ))}
            <Divider mt={2} />

            <Text color="#5F90FF" fontWeight="bold" mt={1}>
              {t('pay')}{' '}
              <NumberFormat
                value={formatBalance(mintFee)}
                displayType={'text'}
                thousandSeparator={true}
              />{' '}
              {feeTokenName}
            </Text>
            <Text fontSize={12} textColor="textTips" fontFamily="Arial">
              {t('balance')}:{' '}
              <NumberFormat
                value={formatBalance(feeTokenBal)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </Text>
          </Box>

          <Box mt={5} mb={2} px={3}>
            <Box
              display="inline-block"
              w="16px"
              cursor="pointer"
              position="relative"
              top={'3px'}
              onClick={() => {
                setAgreed(!agreed);
              }}
            >
              {agreed ? <Image src={checkIcon} /> : <Image src={checkIconGrey} />}
            </Box>
            <Text
              display="inline-block"
              fontSize={12}
              textColor="textTips"
              fontFamily="Arial"
              ml={1}
            >
              {t('agree.term')}{' '}
              <Text
                as="span"
                cursor="pointer"
                color="#31C1C1"
                onClick={() => history.push('/disclaimer')}
              >
                {t('eula')}
              </Text>
            </Text>
          </Box>
          {!mintable && (
            <Box my={4} px="7">
              <Text fontSize="14px" textColor="#FF0000">
                Mint condition is not met, please check if you hold the corresponding NFT and the
                required number of DKPs and Tokens.
              </Text>
            </Box>
          )}
          <Box px="7" mb="5">
            <ApproveBtn
              token={feeToken}
              tokenName={feeTokenName}
              contractAddr={getPoolAddr()}
              disabled={!agreed}
              w="100%"
              fontSize={18}
              button={
                <Button
                  w="100%"
                  fontSize={18}
                  disabled={!mintable || !agreed}
                  onClick={redeem}
                  isLoading={loading}
                >
                  {t('mint').toUpperCase()}
                </Button>
              }
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MintModal;
