import React, { useState, useEffect } from 'react';
import { Box, Accordion } from '@chakra-ui/react';
import ReactGA from 'react-ga';

import Pool from './poolItem';

const PoolList = (props: any) => {
  const { pools = [], isSlice = false } = props;

  const poolList = isSlice ? pools.slice(0, 5) : pools;

  const handleChange = (idx: number) => {
    if (idx >= 0) {
      const pool = poolList[idx];
      ReactGA.modalview(`/stake/${pool.name}/show-redeems`);
    }
  };

  return (
    <Box mt={{ base: 2, lg: 4 }}>
      <Accordion allowToggle mx="-16px" onChange={(idx: number) => handleChange(idx)}>
        {poolList.map((pool: any, idx: number) => (
          <Pool pool={pool} key={idx} />
        ))}
      </Accordion>
    </Box>
  );
};

export default PoolList;
