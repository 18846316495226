import Rekv from 'rekv';

const pool = new Rekv({
  pools: {
    0: {
      id: 0,
      name: 'Donate OVO',
      token: 'wxDai',
    },
    1: {
      id: 1,
      name: 'Stake pool',
      token: 'RICE',
    },
    2: {
      id: 2,
      name: 'Task pool',
      token: 'RICE',
    },
  },
});

export default pool;
