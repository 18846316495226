import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Panel from '../../components/panel';
import Perks from '../perks';
import Trophies from '../trophies';
import Tokens from '../../components/tokens';

const Page = () => {
  const { t } = useTranslation();

  return (
    <Panel p={0} border="0" overflow="hidden">
      <>
        <Tabs isFitted variant="unstyled">
          <TabList background="#eee" fontFamily="Eurostile">
            <Tab
              py={{ base: 2, lg: 4 }}
              fontWeight="bold"
              _selected={{
                background: '#fff',
              }}
              _focus={{ outline: 'none' }}
            >
              {t('tokens')}
            </Tab>
            <Tab
              fontWeight="bold"
              _selected={{
                background: '#fff',
              }}
              _focus={{ outline: 'none' }}
            >
              {t('minted')}
            </Tab>
            <Tab
              fontWeight="bold"
              _selected={{
                background: '#fff',
              }}
              _focus={{ outline: 'none' }}
            >
              {t('won')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Tokens />
            </TabPanel>
            <TabPanel>
              <Trophies />
            </TabPanel>
            <TabPanel>
              <Perks />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box h={10}></Box>
      </>
    </Panel>
  );
};

export default Page;
